import React from "react";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import MainTileComponent from "../mainPage/MainTileComponent";

export default class FeaturedWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div class="featuredContainer">
        <div class="columns">
          <div class="column">
            <MainTileComponent
              source="/files/facecam_giveaway1.jpg"
              videoId="dS0glzMgERc"
              titleText="Free futuristic facecam overlay"
            />
          </div>
          <div class="column">
            <MainTileComponent
              source="/files/thumbYoutube_striped.jpg"
              videoId="Oi0C5ORucnA"
              titleText="Striped"
            />
          </div>
          <div class="column">
            {" "}
            <MainTileComponent
              source="/files/thumbYoutube_bioWeapon.jpg"
              videoId="n_9Q-T_vMCQ"
              titleText="BIOWEAPON"
            />
          </div>
          <div class="column">
            {" "}
            <MainTileComponent
              source="/files/colorfulFreeWebcam.png"
              videoId="URmhA0RHpkY"
              titleText="Free Colorful Twitch Webcam Overlay Template "
            />
          </div>
        </div>
      </div>
    );
  }
}
