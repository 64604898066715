import React from "react";
import YouTube from "react-youtube";
import FeaturedWidget from "../featured/FeaturedWidget";
import Helmet from "helmet";
import videoConfig from "../videoConfig.json";
import MainTileComponent from "../mainPage/MainTileComponent";
const axios = require("axios");
const config = require("../config.json");

var myTimer;

export default class VideoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      current: 0,
      duration: 1,
      isAvailable: false,
      email: "",
      wrongEmail: false,
      isDownloading: false,
      isClicked: false,
      clicked: 0,
      isAuthenticated: false,
      user: null,
      token: "",
      isAuth: false,
      isDownClicked: false,
      numberCategories: 4,
      numberCol: 1,
      reco: [],
    };
  }

  // onClick = () => {
  //   let self = this;
  //   axios
  //     .post(`${config.api.invokeUrl}/tweetlogin`, {
  //       downloadID: self.props.videoId.substr(1),
  //     })
  //     .then(function (resp) {
  //       window.location.assign(resp.data.body);
  //     });
  // };

  sendEmail = () => {
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // if (re.test(this.state.email)) {
    //   try {
    //     axios
    //       .post(`${config.api.invokeUrl}/postemail`, {
    //         email: this.state.email,
    //         name: this.props.name,
    //       })
    //       .then((resp) => {
    //         this.setState({ wrongEmail: false });

    //       });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // } else {
    //   this.setState({ isDownClicked: true });
    //   this.setState({ wrongEmail: true });

    this.setState({ isDownloading: true });

    var element = document.createElement("a");
    element.setAttribute("href", this.props.link);
    element.setAttribute("download", "Free_template_by_pinada");

    document.body.appendChild(element);

    //onClick property
    element.click();
    document.body.removeChild(element);
  };
  componentDidUpdate = () => {
    if (
      this.state.current >
      this.state.duration - (this.props.perc / 100) * this.state.duration
    ) {
      this.setState({ isAvailable: true });
      this.setState({ current: 0 });
    }
  };

  componentDidMount() {
    let json;
    let tempArr = [];
    let arr = [];
    var arrPacks = Array.from(
      Array(this.state.numberCategories),
      () => new Array(5)
    );
    let temp = [];

    // loop for facecams and packs path
    json = JSON.parse(JSON.stringify(videoConfig));
    Object.keys(json).forEach(function (key) {
      tempArr.push(json[key]);
    });

    for (var cat = 0; cat <= this.state.numberCategories - 1; cat++) {
      arr = [];

      Object.keys(tempArr[cat + 1]).forEach(function (key) {
        arr.push(tempArr[cat + 1][key]);
      });
      let nbLigne =3;

      for (let j = 0; j < nbLigne; j++) {
        temp = [];
        for (
          let i = j * this.state.numberCol;
          i < j * this.state.numberCol + this.state.numberCol;
          i++
        ) {
          if (arr[i]) {
            temp.push(
              <div class="column">
                <MainTileComponent
                  source={arr[i].tileImageSrc}
                  videoId={arr[i].url}
                  titleText={arr[i].tileText}
                />
              </div>
            );
          } else {
            temp.push(<div class="column"></div>);
          }
        }

        arrPacks[cat][j] = <div class="is-variable columns is-5">{temp}</div>;
      }
    }
    this.setState({ reco: arrPacks });

    this.setState({ randNum: Math.floor(Math.random() * 3) });
  }
  stopPlaying = () => {
    this.setState({ isPlaying: false });
  };
  increment = (event) => {
    clearInterval(myTimer);
    var self = this;
    this.setState({ isPlaying: true });
    this.setState({
      duration:
        event.target.getDuration() -
        (this.props.perc / 100) * event.target.getDuration(),
    });

    if (this.state.isAvailable == false) {
      myTimer = setInterval(function () {
        if (self.state.isPlaying == true) {
          self.setState({ current: self.state.current + 1 });
        } else {
          clearInterval(myTimer);
        }
      }, 1000);
    } else {
      clearInterval(myTimer);
    }
  };

  handleInputChange = (event) => {
    this.setState({ isDownClicked: false });
    this.setState(
      {
        email: event.target.value,
      },
      function () {}
    );
  };

  render() {
    let perc = Math.trunc(
      (this.state.current /
        (this.state.duration - (this.props.perc / 100) * this.state.duration)) *
        100
    );
    let opts = {
      height: "315",
      width: "560",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    return (
      <div style={{ width: "95%", margin: "0 auto" }}>
        <div className="columns">
          <div className="column ">
            <div></div>
          </div>
          <div className="column ">
            <div style={{ margin: "0 auto", marginTop: 70 }}>
              <Helmet>
                <title>{this.props.pageTitle}</title>
                <meta
                  name="description"
                  content="Download free streaming overlays for twitch and other streaming platform !
             Download free stream panels, facecam overlays, custom screens. 
             Animated and non animated streaming overlay template. All Streaming platform (youtube, twitch, mixer, etc)"
                ></meta>
              </Helmet>

              <div class="">
                <h2 style={{ fontWeight: "400" }} class="titleWatch">
                  Watch the video to unluck the download link !
                </h2>
                <br></br>
                <h2
                  style={{ fontWeight: "300", marginBottom: 0 }}
                  class="titleRead"
                >
                  My templates are all free. Watching the video helps me gain
                  more traction on youtube and is the reason you don't have to
                  pay to get this template ! Liking the video and commenting
                  also help. Thank you :)
                </h2>
                {/* <h4 class="subtitle">By Pinada</h4> */}
                <br></br>
                <YouTube
                  videoId={this.props.videoId}
                  opts={opts}
                  onPlay={this.increment}
                  onPause={this.stopPlaying}
                  onEnd={this.stopPlaying}
                />
                <br></br>

                {this.state.isAvailable ? (
                  <div>
                    <h3 class="title red" style={{ width: "100%" }}>
                      Done
                    </h3>
                  </div>
                ) : (
                  <div>
                    <progress
                      class="progress is-primary"
                      value={perc}
                      max="100"
                    ></progress>
                    <h3>{perc + " % watched"}</h3>
                  </div>
                )}
                {this.state.isAvailable ? (
                  <div class="">
                    <div class="">
                      <a
                        target="_blank"
                        href="https://twitter.com/PinadaMedia"
                        class="subtitle"
                      >
                        Follow on twitter to get latest news .
                      </a>

                      <br></br>
                      <a href="https://www.behance.net/Pinada" class="subtitle">
                        Follow on behance to see my latest work.
                      </a>
                      {/* <div class="column">
                <h4 style={{ marginBottom: "25px" }} class="subtitle">
                  Enter your email address below to download:
                </h4>{" "}
                <div
                  style={{ width: "60%", margin: "0 auto" }}
                  class="control has-icons-left has-icons-right"
                >
                  <input
                    class="input"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  ></input>
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="icon is-small is-right">
                    <i class="fas fa-check"></i>
                  </span>
                </div>
              </div> */}

                      <div class="column">
                        {/* <a
                  class="bd-tw-button button"
                  data-social-network="Twitter"
                  onClick={this.onClick}
                >
                  <span class="icon">
                    <i class="fab fa-twitter"></i>
                  </span>
                  <h5 class="subtitle">LOG IN</h5>
                </a> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <br></br>
                {/* {this.state.wrongEmail && this.state.email.length === 0 ? (
          <p class="subtitle" style={{ color: "red" }}>
            Please enter a valid email address or login with twitter
          </p>
        ) : (
          ""
        )} */}

                {/* {this.state.wrongEmail &&
        this.state.email.length > 0 &&
        this.state.isDownClicked ? (
          <p class="subtitle" style={{ color: "red" }}>
            Please enter a valid email
          </p>
        ) : (
          ""
        )} */}

                <button
                  className="button is-fullwidth is-medium is-success"
                  onClick={this.sendEmail}
                  disabled={this.state.isAvailable ? false : true}
                >
                  Download
                </button>

                <br></br>
                <br></br>
                {this.state.isDownloading ? (
                  <div>
                    <p className="subtitle">Thanks for downloading !</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div style={{ marginTop: 10, height: 30 }} class="">
                <a
                  href="https://www.hypepinada.com/work/"
                  style={{ textAlign: "left", textDecoration: "underline" }}
                  class="titleRecommanded"
                >
                  Work with me
                </a>
              </div>
            </div>
          </div>
          <div className="column is-3">
            <div>
              <div style={{ marginTop: 70, height: 750 }} class="mainContainer">
                <h6 style={{ textAlign: "left" }} class="titleRecommanded">
                  You might like ...
                </h6>

                <div style={{maxHeight:750}}>{this.state.reco[this.state.randNum]}</div>
                <div style={{ textAlign: "left" }}>
                  <a
                    href="https://www.hypepinada.com/"
                    style={{ textAlign: "left", textDecoration: "underline" }}
                    class="titleRecommanded"
                  >
                    See all freebies ...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
    );
  }
}
