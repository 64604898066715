import React from "react";
import YouTube from "react-youtube";
import FeaturedWidget from "../featured/FeaturedWidget";
import Helmet from "helmet";
const axios = require("axios");
const config = require("../config.json");

var myTimer;

export default class VideoContainerGiveaways extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      current: 0,
      duration: 1,
      isAvailable: false,
      email: "",
      wrongEmail: false,
      enterDone: false,
    };
  }

  sendEmail = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(this.state.email)) {
      try {
        axios
          .post(`${config.api.invokeUrl}/postemail`, {
            email: this.state.email,
            name: this.props.name,
          })
          .then((resp) => {
            this.setState({ wrongEmail: false });
            this.setState({ enterDone: true });
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({ wrongEmail: true });
    }
  };
  componentDidUpdate = () => {
    if (
      this.state.current >
      this.state.duration - (this.props.perc / 100) * this.state.duration
    ) {
      this.setState({ isAvailable: true });
      this.setState({ current: 0 });
    }
  };
  stopPlaying = () => {
    this.setState({ isPlaying: false });
  };
  increment = (event) => {
    clearInterval(myTimer);
    var self = this;
    this.setState({ isPlaying: true });
    this.setState({
      duration:
        event.target.getDuration() -
        (this.props.perc / 100) * event.target.getDuration(),
    });

    if (this.state.isAvailable == false) {
      myTimer = setInterval(function () {
        if (self.state.isPlaying == true) {
          self.setState({ current: self.state.current + 1 });
        } else {
          clearInterval(myTimer);
        }
      }, 1000);
    } else {
      clearInterval(myTimer);
    }
  };

  handleInputChange = (event) => {
    this.setState(
      {
        email: event.target.value,
      },
      function () {
        console.log(this.state.email);
      }
    );
  };

  componentDidMount() {}
  render() {
    let perc = Math.trunc(
      (this.state.current /
        (this.state.duration - (this.props.perc / 100) * this.state.duration)) *
        100
    );
    let opts = {
      height: "390",
      width: "640",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    return (
      <div style={{ marginTop: 100 }}>
        <Helmet>
          <title>{this.props.pageTitle}</title>
          <meta
            name="description"
            content="Download free streaming overlays for twitch and other streaming platform ! "
          ></meta>
        </Helmet>
        <h2 class="title">Watch the video to enter the giveaway ! (BETA)</h2>
        {/* <h4 class="subtitle">By Pinada</h4> */}
        <br></br>

        <YouTube
          videoId={this.props.videoId}
          opts={opts}
          onPlay={this.increment}
          onPause={this.stopPlaying}
          onEnd={this.stopPlaying}
        />
        <br></br>

        <div>
          {this.state.isAvailable ? (
            <h3 class="title" style={{ color: "#00d1b2" }}>
              Done
            </h3>
          ) : (
            <div>
              <progress
                class="progress is-primary"
                value={perc}
                max="100"
              ></progress>
              <h3>{perc + " % watched"}</h3>
            </div>
          )}
        </div>
        <br></br>
        {this.state.isAvailable ? (
          <h4 style={{ marginBottom: "25px", fontSize: 16 }} class="title">
            Enter your email address below to enter:
          </h4>
        ) : (
          ""
        )}

        {this.state.isAvailable ? (
          <div
            style={{ width: "60%", margin: "0 auto" }}
            class="control has-icons-left has-icons-right"
          >
            <input
              class="input"
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
            ></input>
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
            <span class="icon is-small is-right">
              <i class="fas fa-check"></i>
            </span>
          </div>
        ) : (
          ""
        )}
        <br></br>
        {this.state.enterDone ? (
          <p class="subtitle" style={{ color: "green" }}>
            You have successfully entered the giveaway ! Thank you .
          </p>
        ) : (
          ""
        )}
        {this.state.wrongEmail ? (
          <p class="subtitle" style={{ color: "red" }}>
            Please enter a valid email address
          </p>
        ) : (
          ""
        )}
        <br></br>
        {this.state.isAvailable ? (
          <button className="button is-success" onClick={this.sendEmail}>
            Enter
          </button>
        ) : (
          ""
        )}
        <br></br>
        <br></br>
        {this.state.enterDone ? (
          <div>
            <p className="subtitle">Here is other templates you might like !</p>
            <FeaturedWidget />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
