import React from "react";
const axios = require("axios");
const config = require("../config.json");

export default class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      succes: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  unsubscribe = () => {
    try {
      axios
        .delete(`${config.api.invokeUrl}/unsubemail`, {
          email: this.state.email,
        })
        .then((resp) => {
          this.setState({ success: true });
        });
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    return (
      <div>
        <h5 style={{ marginBottom: "25px" }} class="title">
          Please enter your email address to unsubscribe :{" "}
        </h5>
        <input
          class="input"
          type="email"
          placeholder="Email"
          value={this.state.email}
          onChange={this.handleInputChange}
        ></input>
        <br></br>
        <br></br>
        <button class="button is-success" onClick={this.unsubscribe}>
          Unsubscribe
        </button>
        <br></br>
        <br></br>
        {this.state.success ? (
          <h5 class="subtitle" style={{ color: "#00d1b2" }}>
            Unsubscribe successful
          </h5>
        ) : (
          ""
        )}
      </div>
    );
  }
}
