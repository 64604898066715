import React from "react";
import { Link } from "react-router-dom";

export default class MainTileComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {}

  render() {
    return (
      <div
        onClick={() => {
          if (
            this.props.videoId.includes("behance") ||
            this.props.videoId.includes("twitter")
          ) {
            window.location = this.props.videoId;
          } else {
            window.location = "https://www.hypepinada.com" + this.props.videoId;
          }
        }}
      >
        <article class="link tile is-child notification ">
          <figure class="image is-16by9">
            <img
              class="hoverTile"
              style={{ borderRadius: 4 }}
              src={this.props.source}
            ></img>
          </figure>
        </article>
        {/* <p
          style={{
            color: "white",
            marginTop: 10,
            fontWeight: "600",
            fontSize: "12px",
            float: "left",
          }}
          class="redHover"
        >
          {this.props.titleText}
        </p> */}
        {/* <div class="smallContainer">
          <p>FREE</p>
        </div> */}
      </div>
    );
  }
}
