import React from "react";
import YouTube from "react-youtube";
import FeaturedWidget from "../featured/FeaturedWidget";
import Helmet from "helmet";
const axios = require("axios");
const config = require("../config.json");

var myTimer;

export default class AboutMe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate = () => {};

  render() {
    return (
      <div style={{ marginTop: 100 }}>
        <Helmet>
          <title>About me - Pinada</title>
          <meta
            name="description"
            content="Hi ! I am Adam. I love doing graphic design, specially gaming
            designs. You will see that most of my designs are free. I make
            money with ads on my website and hopefully soon on Youtube .  "
          ></meta>
        </Helmet>
        <div style={{ width: "70%", marginLeft: "15%" }} class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-128x128">
                <img src="/files/aboutme.jpg" alt="Image"></img>
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Adam Pinheiro</strong>{" "}
                  <small>youtubifysupp@gmail.com</small> <br></br>
                  Hi ! I am Adam. I love doing graphic design, specially gaming
                  designs. You will see that most of my designs are free. I make
                  money with ads on my website and hopefully soon on Youtube . I
                  am also a software engineer by day. Thanks for reading :)
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_donations" />
                  <input type="hidden" name="business" value="YGZJ37ZH5A2LL" />
                  <input type="hidden" name="currency_code" value="CAD" />
                  <input
                    type="image"
                    src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                    border="0"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/en_CA/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </div>
            </div>
          </article>
        </div>
        <br></br>
      </div>
    );
  }
}
