import React, { useState } from "react";
import Helmet from "helmet";
import videoConfig from "../videoConfig.json";
import { renderIntoDocument } from "react-dom/test-utils";
const axios = require("axios");
const config = require("../config.json");

export default class DownloadDone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuth: true,
      DLink: "",
    };
  }

  componentWillMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("oauth_token");
    const verifier = query.get("oauth_verifier");
    const id = query.get("id");

    if (token && verifier) {
      this.setState({ isAuth: true });
      let self = this;

      axios
        .post(`${config.api.invokeUrl}/postlogin`, {
          token: token,
          verifier: verifier,
        })
        .then(function (resp) {
          console.log(resp.data);
          if (resp.data.body) {
            axios
              .post(`${config.api.invokeUrl}/posttweet`, {
                secret: resp.data.body.accessTokenSecret,
                access: resp.data.body.accessToken,
              })
              .then(function (resp) {
                let arr = [];
                let tempArr = [];
                self.setState({ isAuth: true });
                let json = JSON.parse(JSON.stringify(videoConfig));
                Object.keys(json).forEach(function (key) {
                  tempArr.push(json[key]);
                });

                for (let i = 1; i <= 3; i++) {
                  arr = [];
                  Object.keys(tempArr[i]).forEach(function (key) {
                    arr.push(tempArr[i][key]);
                  });

                  arr.map((item) => {
                    if (item.url.substr(1) === id) {
                      self.setState({ DLink: item.link }, function () {
                        self.initDownload();
                      });
                    }
                  });
                }
              });
          }
        });
    }
  }
  initDownload = () => {
    console.log(this.state.DLink);
    var element = document.createElement("a");
    element.setAttribute("href", this.state.DLink);
    element.setAttribute("download", "Free_template_by_pinada");
    document.body.appendChild(element);
    //onClick property
    element.click();
    document.body.removeChild(element);
  };
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Helmet>
          <title>Download free streaming overlays and enter giveaways !</title>
          <meta
            name="description"
            content="Download free streaming overlays for twitch and other streaming platform !
            Download free stream panels, facecam overlays, custom screens. 
            Animated and non animated streaming overlay template. All Streaming platform (youtube, twitch, mixer, etc)"
          ></meta>
        </Helmet>
        {this.state.isAuth ? (
          <div>
            <h2 className="title" style={{ marginTop: 200 }}>
              Thanks for downloading ! Your download will begin shortly
            </h2>

            <h5 className="subtitle" style={{ marginTop: 20 }}>
              If it doesn't,<a onClick={this.initDownload}>click here</a>
            </h5>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
