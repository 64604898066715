import React from "react";
import MainTileComponent from "../mainPage/MainTileComponent";
import Helmet from "helmet";
import projectConfig from "./projectConfig.json";

const axios = require("axios");
export default class Work extends React.Component {
  constructor(props) {
    super(props);

    this.state = { numberCategories: 2, numberCol: 2 };
  }

  componentDidUpdate = () => {};

  render() {
    let json;
    let tempArr = [];
    let arr = [];
    var arrPacks = Array.from(
      Array(this.state.numberCategories),
      () => new Array(5)
    );
    let temp = [];

    // loop for facecams and packs path
    json = JSON.parse(JSON.stringify(projectConfig));
    Object.keys(json).forEach(function (key) {
      tempArr.push(json[key]);
    });

    for (var cat = 0; cat <= this.state.numberCategories - 1; cat++) {
      arr = [];

      Object.keys(tempArr[cat]).forEach(function (key) {
        arr.push(tempArr[cat][key]);
      });
      let nbLigne = Math.ceil(arr.length / this.state.numberCol);
      console.log(nbLigne);
      for (let j = 0; j < nbLigne; j++) {
        temp = [];
        for (
          let i = j * this.state.numberCol;
          i < j * this.state.numberCol + this.state.numberCol;
          i++
        ) {
          if (arr[i]) {
            temp.push(
              <div class="column">
                <MainTileComponent
                  source={arr[i].tileImageSrc}
                  videoId={arr[i].url}
                  titleText={arr[i].tileText}
                />
              </div>
            );
          } else {
            temp.push(<div class="column"></div>);
          }
        }

        arrPacks[cat][j] = <div class="is-variable columns is-5">{temp}</div>;
      }
    }
    return (
      <div style={{ marginTop: 100, width: "70%" }}>
        <Helmet>
          <title>Work - Pinada</title>
          <meta
            name="description"
            content="Hi ! I am Adam. I love doing graphic design, specially gaming
            designs. You will see that most of my designs are free. I make
            money with ads on my website and hopefully soon on Youtube .  "
          ></meta>
        </Helmet>

        <br></br>
  
        <h6 class="title titleCategory">Work witth me</h6>
        <p>I am a sotfware engineer with over 3 years experience in web developpement. Feel free to contact me on my personnal website for building websites / web apps. <a href="https://www.adampinheiro.ca/">See personnal website.</a></p>
      
      </div>
    );
  }
}
