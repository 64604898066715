import React, { useState } from "react";
import videoConfig from "../videoConfig.json";
import MainTileComponent from "./MainTileComponent";
import Helmet from "helmet";
const axios = require("axios");
const config = require("../config.json");

export default class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberCategories: 5,
      numberCol: 3,
    };
  }

  componentDidMount() {
    // const query = new URLSearchParams(this.props.location.search);
    // const token = query.get("oauth_token");
    // const verifier = query.get("oauth_verifier");
    // if (token && verifier) {
    //   axios
    //     .post(`${config.api.invokeUrl}/postlogin`, {
    //       token: token,
    //       verifier: verifier,
    //     })
    //     .then(function (resp) {
    //       axios
    //         .post(`${config.api.invokeUrl}/posttweet`, {
    //           secret: resp.data.body.accessTokenSecret,
    //           access: resp.data.body.accessToken,
    //         })
    //         .then(function (resp) {});
    //     });
    // }
  }

  render() {
    let json;
    let tempArr = [];
    let arr = [];
    var arrPacks = Array.from(
      Array(this.state.numberCategories),
      () => new Array(5)
    );
    let temp = [];

    // loop for facecams and packs path
    json = JSON.parse(JSON.stringify(videoConfig));
    Object.keys(json).forEach(function (key) {
      tempArr.push(json[key]);
    });

    for (var cat = 0; cat <= this.state.numberCategories - 1; cat++) {
      arr = [];

      Object.keys(tempArr[cat + 1]).forEach(function (key) {
        arr.push(tempArr[cat + 1][key]);
      });
      let nbLigne = Math.ceil(arr.length / this.state.numberCol);
      console.log(nbLigne);
      for (let j = 0; j < nbLigne; j++) {
        temp = [];
        for (
          let i = j * this.state.numberCol;
          i < j * this.state.numberCol + this.state.numberCol;
          i++
        ) {
          if (arr[i]) {
            temp.push(
              <div class="column">
                <MainTileComponent
                  source={arr[i].tileImageSrc}
                  videoId={arr[i].url}
                  titleText={arr[i].tileText}
                />
              </div>
            );
          } else {
            temp.push(<div class="column"></div>);
          }
        }

        arrPacks[cat][j] = <div class="is-variable columns is-5">{temp}</div>;
      }
    }

    return (
      <div style={{ width: "100%" }}>
        <Helmet>
          <title>Download free streaming overlays and enter giveaways !</title>
          <meta
            name="description"
            content="Download free streaming overlays for twitch and other streaming platform !
            Download free stream panels, facecam overlays, custom screens. 
            Animated and non animated streaming overlay template. All Streaming platform (youtube, twitch, mixer, etc)"
          ></meta>
        </Helmet>
        <section class="hero is-light">
          <div class="hero-body">
            <h1
              className="titleHero"
              style={{
                margin: "40px 0px 50px 0px",
                fontSize: 35,
                fontWeight: "600",
              }}
            >
              Welcome
            </h1>
            <h5
              style={{
                color: "rgb(180, 180, 180)",
                fontWeight: "300",
                marginBottom: 100,
              }}
              className="subtitle"
            ></h5>
          </div>
        </section>
        <div style={{ width: "70%", margin: "0 auto" }}>
          <h6 class="title titleCategory">Free Overlay Packs</h6>
          <div className="mainContainer">{arrPacks[0]}</div>
          <h6 class="title titleCategory">Free Facecam Overlays</h6>
          <div className="mainContainer">{arrPacks[1]}</div>
          <h6 class="title titleCategory">Free Panels</h6>
          <div className="mainContainer">{arrPacks[2]}</div>
          <h6 class="title titleCategory">Free Twitter headers</h6>
          <div className="mainContainer">{arrPacks[3]}</div>
          <h6 class="title titleCategory">Free Xfer Serum Skins</h6>
          <div className="mainContainer">{arrPacks[4]}</div>
        </div>
      </div>
    );
  }
}
