import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import VideoContainer from "./videoContainer/VideoContainer";
import Work from "./work/Work";
import MainPage from "./mainPage/MainPage";
import Unsubscribe from "./unsubscribe/Unsubscribe";
import VideoContainerGiveaways from "./videoContainer/VideoContainerGiveaway";
import ScrollToTop from "./other/ScrollToTop";
import videoConfig from "./videoConfig.json";
import AboutMe from "./about/AboutMe";
import DownloadDone from "./other/DownloadDone";
function App() {
  // loop for giveaways path
  let giveaways = JSON.parse(JSON.stringify(videoConfig.Giveaways));
  let giveawaysArr = [];
  for (var key in giveaways) {
    if (giveaways.hasOwnProperty(key)) {
      var val = giveaways[key];

      giveawaysArr.push(
        <Route
          exact
          path={val.url}
          render={(props) => (
            <VideoContainerGiveaways
              pageTitle={val.metaTitle}
              name={val.name}
              {...props}
              videoId={val.url}
              perc={val.perc}
              link={val.link}
            />
          )}
        />
      );
    }
  }
  let json;
  let tempArr = [];
  let arr = [];
  let Arr = [];
  let downloadDone = [];

  // loop for facecams and packs path
  json = JSON.parse(JSON.stringify(videoConfig));
  Object.keys(json).forEach(function (key) {
    tempArr.push(json[key]);
  });

  for (let i = 1; i <= 4; i++) {
    arr = [];
    Object.keys(tempArr[i]).forEach(function (key) {
      arr.push(tempArr[i][key]);
    });
    arr.map((item) => {
      Arr.push(
        <Route
          exact
          path={item.url}
          render={(props) => (
            <VideoContainer
              pageTitle={item.metaTitle}
              name={item.name}
              {...props}
              videoId={item.url}
              perc={item.perc}
              link={item.link}
            />
          )}
        />
      );
    });
  }

  return (
    <div className="App">
      <header className="App-header">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-start" style={{ margin: 0 }}>
            <div class="navbar-brand">
              <a class="navbar-item" href="/">
                <p class="logo" style={{ color: "white " }}>
                  Adam Pinheiro
                </p>
              </a>

              <a
                role="button"
                class="navbar-burger burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div id="navbarBasicExample" class="navbar-menu">
              <div class="navbar-start">
                <a href="/" style={{ fontSize: "16px" }} class="navbar-item">
                  Freebies
                </a>

             
                <a
                  href="/aboutme"
                  style={{ fontSize: "16px" }}
                  class="navbar-item"
                >
                  About me
                </a>
              </div>
            </div>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="field is-grouped">
                <p class="control">
                  <a
                    class=""
                    data-social-network="Twitter"
                    data-social-action="tweet"
                    data-social-target="https://bulma.io"
                    target="_blank"
                    href="https://twitter.com/PinadaMedia"
                  >
                    <span class="icon">
                      <i
                        style={{
                          color: "white",
                          marginTop: 15,
                          marginRight: 39,
                        }}
                        class="fab fa-twitter"
                      ></i>
                    </span>
                  </a>
                </p>
                <p class="control">
                  <a class="" href="https://www.behance.net/Pinada">
                    <span class="icon">
                      <i
                        style={{
                          color: "white",
                          marginTop: 15,
                          marginRight: 19,
                        }}
                        class="fab fa-behance"
                      ></i>
                    </span>
                  </a>
                </p>
                <p style={{ marginTop: 10 }} class="control">
                  <form
                    action="https://www.paypal.com/cgi-bin/webscr"
                    method="post"
                    target="_top"
                  >
                    <input type="hidden" name="cmd" value="_donations" />
                    <input
                      type="hidden"
                      name="business"
                      value="YGZJ37ZH5A2LL"
                    />
                    <input type="hidden" name="currency_code" value="CAD" />
                    <input
                      type="image"
                      src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    />
                    <img
                      alt=""
                      border="0"
                      src="https://www.paypal.com/en_CA/i/scr/pixel.gif"
                      width="1"
                      height="1"
                    />
                  </form>
                </p>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="main">
        <BrowserRouter>
          <ScrollToTop>
            <Route exact path="/" component={MainPage} />
            {Arr}
            {giveawaysArr}
            <Route
              exact
              path={"/download_"}
              render={(props) => <DownloadDone {...props} />}
            />
            <Route exact path="/unsubscribe" component={Unsubscribe} />
            <Route exact path="/aboutme" component={AboutMe} />
            <Route exact path="/work" component={Work} />
          </ScrollToTop>
        </BrowserRouter>
      </div>
      <footer class="footer">
        <div class="content has-text-centered">
          <p>
            <strong>PINADA</strong> by <a href="https://www.pinero.ca/">pinero</a>.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
